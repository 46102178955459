<template>
    <div class="container rtl">

        <div class="row py-3 no-print">
            <div class="col">
                <form @submit.prevent="additem" autocomplete="off">
                    <div class="row py-3">
                        <div class="col"><span>زیاکردنی بەرهەم</span></div>
                        <div class="btn-group" data-toggle="buttons">
                            <label class="btn btn-primary active">
                                نوێکردنەوە
                                <input type="checkbox" v-model="resetable">
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label>باڕکۆد </label>
                            <input v-model="items.item_barcode" class="form-control" placeholder="باڕکۆد " type="number"
                                step="any" required @dblclick="getbarcode">
                            <input type="hidden" v-model="barcode">
                        </div>

                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label>ناوی بەرهەم</label>
                            <input v-model="items.item_name" class="form-control" placeholder="ناوی بەرهەم" type="text"
                                required>
                        </div>

                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label>جۆر</label>
                            <div class="form-group">
                                <select class="form-control" v-model="items.category_id">
                                    <option v-for="(item, index) in category" :key="index" :value="item.category_id">
                                        {{item.category_name}} ({{item.store_name}})</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label>مەخزەن</label>
                            <div class="form-group">
                                <select class="form-control" :disabled="auth.user.user_role == 'company'"
                                    v-model="items.store_id">
                                    <option v-for="(item, index) in stores" :key="index" :value="item.store_id">
                                        {{item.store_name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label>کۆمپانیا</label>
                            <div class="form-group">
                                <select class="form-control" v-model="items.company_id">
                                    <option v-for="(item, index) in company" :key="index" :value="item.company_id">
                                        {{item.company_name}} - {{item.store_name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label>کێشی یەکەی تاک </label>
                            <input v-model="items.item_kg" class="form-control" placeholder="کێشی یەکەی تاک " step="any"
                                type="number" required>
                        </div>


                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label> ژمارەی یەکەی تاک لە کۆ </label>
                            <input v-model="items.item_quantity" class="form-control"
                                placeholder=" ژمارەی یەکەی تاک لە کۆ " type="number" step="any" required>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label> کەمترین یەکەی کۆ </label>
                            <input v-model="items.item_notification" class="form-control"
                                placeholder="کەمترین یەکەی کۆ " type="number" step="any" required>
                        </div>

                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label>یەکەی کۆ</label>
                            <div class="form-group">
                                <select class="form-control" v-model="items.item_unit">
                                    <option v-for="(item, index) in units" :key="index" :value="item.unit_name">
                                        {{item.unit_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label>یەکەی تاک</label>
                            <div class="form-group">
                                <select class="form-control" v-model="items.item_unit_tak">
                                    <option v-for="(item, index) in units" :key="index" :value="item.unit_name">
                                        {{item.unit_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label style="color:white">-</label>
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">زیادکردن</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row my-3 no-print border-top">
            <hr>
        </div>
        <div class="row no-print">
            <div class="col">
                <table class="table table-striped" id="dataTable">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">باڕکۆد </th>
                            <th scope="col">ناوی کاڵا </th>
                            <th scope="col">وێنە </th>
                            <th scope="col"> جۆر </th>
                            <th scope="col">کۆمپانیا </th>
                            <th scope="col">مەخزەن </th>
                            <th scope="col">بڕی ماوە </th>
                            <th scope="col"> نرخی کڕین </th>
                            <th scope="col"> نرخی فرۆشتن </th>
                            <th scope="col">گۆڕانکاری</th>
                            <th scope="col">نرخ</th>
                            <th scope="col">سڕینەوە</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in $store.state.items" :key="index"
                            :class="item.item_status == 'active' ? '' : 'text-danger'">
                            <td>{{item.item_barcode}}</td>
                            <td>{{item.item_name}}</td>
                            <td>
                                <!-- <div v-if="item.item_image" @click="openImageModal(item)" :style="`width:70px; height:70px;background: url(${$api+item.item_image});background-repeat: no-repeat;background-size: contain;`">

                                </div>
                                <div v-if="!item.item_image" @click="openImageModal(item)" :style="`width:70px; height:70px;background: url('https://cdn.icon-icons.com/icons2/1369/PNG/512/-broken-image_89881.png');background-repeat: no-repeat;background-size: contain;`">

                                </div> -->

                                <button @click="openImageModal(item)"
                                    :class="item.item_image ? 'btn btn-success ' : 'btn btn-info '">وێنە </button>
                            </td>
                            <td>{{item.category_name}}</td>
                            <td>{{item.company_name}}</td>
                            <td>{{item.store_name}}</td>
                            <!-- <td>{{item.available_qty}} {{item.item_unit_tak}} ({{item.available_qty_many}} {{item.item_unit}})</td> -->
                            <td>{{item.available_qty}} {{item.item_unit_tak}}</td>
                            <td>{{$service.toIQD( item.item_amount * dolar)}}</td>
                            <td>{{$service.toIQD( item.item_price * dolar)}}</td>
                            <td>
                                <button @click="openitem(item , 'update')" type="button" class="btn btn-success btn-sm">
                                    گۆڕانکاری
                                </button>
                            </td>
                            <td>
                                <button @click="openitem(item , 'price')" type="button" class="btn btn-warning btn-sm">
                                    نرخ
                                </button>
                            </td>
                            <td>
                                <button @click="openitem(item , 'remove')" type="button" class="btn btn-danger btn-sm">
                                    سڕینەوە
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col">
                <table class="table table-striped" style="font-size:14px">
                    <thead class="thead-dark">
                        <tr>
                            <th>#</th>
                            <th scope="col"> جۆر </th>
                            <th scope="col">ناوی کاڵا </th>
                            <th scope="col">بڕی ماوە </th>
                            <th scope="col"> نرخی کڕین </th>
                            <th scope="col"> نرخی فرۆشتن </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in $store.state.items" :key="index"
                            :class="item.item_status == 'active' ? '' : 'text-danger'">
                            <td>{{ index+1 }}</td>
                            <td>{{item.category_name}}</td>
                            <td>{{item.item_name}}</td>
                            <td>{{item.available_qty}} {{item.item_unit_tak}}</td>
                            <td> </td>
                            <td> </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->

        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی بەرهەم</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        دڵنیایت لە سڕینەوەی بەرهەم؟
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger" @click="deleteitem">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Image Modal  -->
        <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">گۆڕانکاری وێنە</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <img v-if="selected_items.item_image" style="width:100%;max-width: 700px;"
                            :src="$api+selected_items.item_image" alt="">
                        <input type="file" name="file" ref="file" id="file" @change="onChangeFileUpload">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <!-- <button type="button" class="btn btn-danger">گۆڕینی وێنە</button> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Update Modal -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="updateitem">
                        <div class="modal-header">
                            <h5 class="modal-title">نوێکردنەوەی بەرهەم</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6 my-1">
                                    <label>باڕکۆد </label>
                                    <input v-model="selected_items.item_barcode" class="form-control"
                                        placeholder="باڕکۆد " type="number" step="any" required>
                                </div>
                                <div class="col-6 my-1">
                                    <label>ناوی بەرهەم</label>
                                    <input v-model="selected_items.item_name" class="form-control"
                                        placeholder="ناوی بەرهەم" type="text" required>
                                </div>


                                <div class="col-6 my-1">
                                    <label>کێشی یەکەی تاک </label>
                                    <input v-model="selected_items.item_kg" class="form-control"
                                        placeholder="کێشی یەکەی تاک " type="text" required>
                                </div>


                                <div class="col-6 my-1">
                                    <label> ژمارەی یەکەی تاک لە کۆ </label>
                                    <input v-model="selected_items.item_quantity" class="form-control"
                                        placeholder=" ژمارەی یەکەی تاک لە کۆ " type="number" step="any" required>
                                </div>

                                <div class="col-6 my-1">
                                    <label>مەخزەن</label>
                                    <div class="form-group">
                                        <select class="form-control" :disabled="auth.user.user_role == 'company'"
                                            v-model="selected_items.store_id">
                                            <option v-for="(item, index) in stores" :key="index" :value="item.store_id">
                                                {{item.store_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-6 my-1">
                                    <label>جۆر</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="selected_items.category_id">
                                            <option v-for="(item, index) in category" :key="index"
                                                :value="item.category_id">
                                                {{item.category_name}} ({{item.store_name}})</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-6 my-1">
                                    <label>کۆمپانیا</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="selected_items.company_id">
                                            <option v-for="(item, index) in company" :key="index"
                                                :value="item.company_id">
                                                {{item.company_name}} - {{item.store_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-6 my-1">
                                    <label>یەکەی کۆ</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="selected_items.item_unit">
                                            <option v-for="(item, index) in units" :key="index" :value="item.unit_name">
                                                {{item.unit_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-6 my-1">
                                    <label>یەکەی تاک</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="selected_items.item_unit_tak">
                                            <option v-for="(item, index) in units" :key="index" :value="item.unit_name">
                                                {{item.unit_name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6 my-1">
                                    <label>چالاکی</label>
                                    <div class="form-group">
                                        <input type="checkbox" v-model="selected_items.item_status" true-value="active"
                                            false-value="deactive" class="form-control">
                                    </div>
                                </div>
                                <div class="col-6 my-1">
                                    <label>لە پەڕەی سەرەکی؟</label>
                                    <div class="form-group">
                                        <input type="checkbox" v-model="selected_items.item_is_ads" true-value="true"
                                            false-value="false" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                            <button type="submit" class="btn btn-success">نوێکردنەوە</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Update Price Modal -->
        <div class="modal fade" id="updatePriceModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="updateitemPrice">
                        <div class="modal-header">
                            <h5 class="modal-title">نوێکردنەوەی نرخەکان</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6 my-1">
                                    <label>باڕکۆد </label>
                                    <input v-model="selected_items.item_barcode" class="form-control"
                                        placeholder="باڕکۆد " type="number" step="any" required>
                                </div>
                                <div class="col-6 my-1">
                                    <label>ناوی بەرهەم</label>
                                    <input v-model="selected_items.item_name" class="form-control"
                                        placeholder="ناوی بەرهەم" type="text" required>
                                </div>
                                <div class="col-6 my-1">
                                    <label>نرخی کڕین</label>
                                    <input v-model="selected_items.item_amount" class="form-control"
                                        placeholder="نرخی کڕین" type="number" step="any" required>
                                </div>
                                <div class="col-6 my-1">
                                    <label>نرخی فرۆشتن</label>
                                    <input v-model="selected_items.item_price" class="form-control"
                                        placeholder="نرخی فرۆشتن" type="number" step="any" required>
                                </div>
                                <!-- <div class="col-6 my-1">
                                    <label>نرخی داشکاندن</label>
                                    <input v-model="selected_items.item_discount_price" class="form-control"
                                        placeholder="نرخی داشکاندن" type="text" required>
                                </div> -->
                                <div class="col-6 my-1">
                                    <label>بەرواری بەسەرچوون</label>
                                    <input v-model="selected_items.item_exp_date" class="form-control" placeholder=""
                                        type="date" required>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                            <button type="submit" class="btn btn-success">نوێکردنەوە</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                selected_items: {},
                items: {
                    store_id: 0
                },
                barcode: null,
                resetable: false,
                msg: {
                    color: '',
                    model: false,
                    text: ''
                },
                file: ''
            }
        },
        computed: {
            allitems() {
                let items = this.$store.getters.items.filter(f => {
                    return f.item_status == 'active'
                })
                let user = this.auth.user
                if (user.user_role != 'super') {
                    items = items.filter(f => {
                        return f.store_id == user.store_id
                    })
                }
                return items
            },
            stores() {
                return this.$store.getters.store
            },
            units() {
                return this.$store.getters.units
            },
            category() {
                return this.$store.getters.category
            },
            auth() {
                return this.$store.getters.auth
            },
            dolar() {
                return this.$store.getters.dollar
            },
            company() {
                return this.$store.getters.company
            },
        },
        methods: {
            uploadImage() {
                let formData = new FormData();
                formData.append('item_id', this.selected_items.item_id);
                formData.append('file', this.file);
                axios.post("items/updateImage.php",
                        formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then((data) => {
                        console.log(data);
                        if (data.data.status == 'ok') {
                            this.getitems()
                            $('#imageModal').modal('hide')
                            this.bar = {
                                model: true,
                                text: 'Image Changed',
                                color: 'blue'
                            }
                        }
                    })
                    .catch(() => {
                        console.log('FAILURE!!');
                    });
            },
            openImageModal(item) {
                $('#imageModal').modal('show')
                this.selected_items = item
            },
            onChangeFileUpload() {
                this.file = this.$refs.file.files[0];
                console.log(this.file);
                this.uploadImage()
            },
            getitems() {
                $('#dataTable').DataTable().destroy();
                axios.get('items/getAvailable.php', {
                        store_id: this.auth.user.store_id,
                        user_role: this.auth.user.user_role == 'company' ? 'company' : null,
                    })
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.$store.state.items = r.data.items
                            this.$nextTick(() => {
                                $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            additem() {
                let params = this.items
                axios.post('items/create.php', params)
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            if (this.resetable) {
                                this.items.item_name = null;
                                this.items.item_barcode = null;
                                this.items.item_kg = null;
                                this.items.item_quantity = null;
                                this.items.item_notification = null;
                            }
                            this.getitems()
                            this.msg = {
                                model: true,
                                text: 'بەرهەم زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        } else {
                            this.$toast(r.data.message);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deleteitem() {
                let params = {
                    item_id: this.selected_items.item_id
                }
                axios.post('items/delete.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.getitems()
                            this.msg = {
                                model: true,
                                text: ' بەرهەم سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            updateitem() {
                let params = this.selected_items
                axios.post('items/update.php', params)
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            this.getitems()
                            $('#updateModal').modal('hide')
                            this.msg = {
                                model: true,
                                text: 'بەرهەم نوێکرایەوە',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            updateitemPrice() {
                let params = this.selected_items
                params.item_amount = params.item_amount / this.dolar
                params.item_price = params.item_price / this.dolar
                params.item_discount_price = params.item_discount_price / this.dolar
                axios.post('items/updatePrice.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.getitems()
                            $('#updatePriceModal').modal('hide')
                            this.msg = {
                                model: true,
                                text: 'بەرهەم نوێکرایەوە',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            openitem(item, type) {
                this.selected_items = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                } else if (type == 'price') {
                    $('#updatePriceModal').modal('show')
                } else {
                    $('#updateModal').modal('show')
                }
            },
            getbarcode() {
                axios.post('items/barcode.php', {
                        getbarcode: true
                    })
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.barcode = r.data.barcode;
                            this.items.item_barcode = this.barcode;
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
        },
        mounted() {
            $('#deleteModal').modal('hide')
            $('#updateModal').modal('hide')
            $('#updatePriceModal').modal('hide')
            $('#dataTable').DataTable();
            if (this.auth.isAuth) {
                this.items.store_id = this.auth.user.store_id
            }
            this.getitems()
        },
    }
</script>